<template>
  <form autocomplete="off" class="">
    <div class="d-flex mb-3 w-100 align-items-center justify-content-between">
      <h6 class="me-2">Patient's Photo & Signature</h6>
      <button type="button" @click="resetForm" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="form-label">Attach Photo</label>
          <div class="d-grid">
              <button type="button"  @click="popupTakePicture =  true" class="btn mb-2 btn-outline-dark">
                    <span class="mdi mdi-camera"></span> Take Picture </button>

              <input type="file" ref="fileInput" accept="image/*" style="display:none;" @change="handleFileChange">
              <button type="button" class="btn btn-outline-primary" @click="triggerFileInput">Upload Image</button>
          </div>
          <div v-if="attachedImage" class="py-3 text-center">
              <img :src="attachedImage" class="w-50" />
          </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-column align-items-center">
          <vue-signature-pad
            id="signature"
            ref="signaturePad"
            width="300px"
            height="200px"
          />
          <div class="mt-3">
            <button class="btn btn-sm btn-outline-dark"  @click.prevent="clear()"
              type="button">Clear Signature</button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
          <button class="btn btn-primary"  @click.prevent="confirmUpdate()"
              type="button">Submit</button>
        </div>
      </div>
    </div>

    <b-modal hide-footer hide-header  v-model="popupTakePicture" centered
      no-close-on-esc no-close-on-backdrop class="web-cam-moal-backdrop" 
      @hide="popupTakePicture = false" body-class="bg-light">
      <vue-web-cam v-if="popupTakePicture"
        @acceptImage="acceptCameraImage($event)"
        @closeMe="popupTakePicture = false" />
    </b-modal>
  </form>
</template>

<script>
import VueSignaturePad from '@/components/VueSignaturePad.vue';
import VueWebCam from '@/components/VueWebCam.vue';

export default {
  components:{
    VueSignaturePad,
    VueWebCam
  },
  props:{
    editItem:{
      type: Object,
      required: true
    },
  },
  data(){
    return{
      attachedImage: null,
      attachedPhoto: null,
      attachedSignature: null,
      popupTakePicture: false,
      mimeType: 'image/png'
    }
  },
  methods:{
    async confirmUpdate() {
      if(!this.attachedPhoto){ this.alertError("Please attach picture"); return;}
      try {
        const signaturePad = this.$refs.signaturePad;

        const isEmpty = signaturePad.isEmpty();
        if (isEmpty) {
          this.alertError("Please the signature is required"); return;
        }
        const { data } = signaturePad.saveSignature();
        const blob = this.$filters.base64ToBlob(data, this.mimeType);
    
        const file = new File([blob], "signature.png", { type: this.mimeType });
        this.attachedSignature = file;

        this.confirmAction({
          text: `You are about to confirm the dispense receipt of: 
          <h6>${this.$filters.number_format(this.editItem.quantity)} ${this.editItem.medicine.minimum_unit}(s) of ${this.editItem.medicine.name}</h6>
          Please ensure your details are accurate.`,
        }).then((result) => {
            if (result.value) { this.update(); }
        });
      } catch (error) {
        console.error("An error occurred while saving the signature:", error);
        this.alertError("An error occurred. Please try again.");
      }
    },
    update(){
      let formData = new FormData();
      formData.append('image_file', this.attachedPhoto)
      formData.append('signature_file', this.attachedSignature)
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/prescribed-drugs/${this.editItem.id}/confirm`, formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('updatedItem', response.data.data)
            this.resetForm()
          }
      })
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    acceptCameraImage(data){
      this.attachedImage = data.url;
      this.attachedPhoto = data.file
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if(file.size > 2097152){
          this.alertError('File size is more than 2MB'); return;
      }
      this.$filters.fileReader(file)
          .then(result => {
              this.attachedImage = result.dataURL;
              this.attachedPhoto = file
          })
          .catch(error => {
              this.alertError('Error reading file '+error);
          });
    },
    resetForm(){
      this.$refs.signaturePad.clearSignature();
      this.$emit("closeMe")
    }
  },
  mounted(){

  }
}
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>