<template>
<div>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-between">
                <div class=""><strong>Quantity #: </strong> <span class="fw-bold fs-5">{{ item.quantity}}</span> </div>

                <div>
                    <button v-if="item.status == 'dispensed'" class="btn me-2 btn-sm btn-primary"
                        @click="showEvidence = true"
                    type="button"> <i class="mdi mdi-image-search align-bottom"></i></button>

                    <button class="btn btn-sm btn-outline-primary" @click="downloadReceipt(item.id)"
                    type="button"> Receipt</button>
                </div>
            </div>
            <hr class="mt-1 mb-2">
            <div class="">
                <div class="row g-2 align-items-center">
                    <div class="col-12">
                        <div v-if="item.medicine" class="">
                            <h5 class="mb-0"> {{ item.medicine.name }} - <span v-if="item.medicine.type"> 
                                {{item.medicine.type.title}}
                                </span>
                            </h5>
                            <small>
                                {{item.medicine.description}}
                            </small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Status: </strong> <span v-html="statusBadge(item.status)"></span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Dosage: </strong> <span> {{ item.dosage }}</span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Frequency: </strong> <span> {{ item.frequency }}</span></div>
                    </div>
                    <div class="col-md-6">
                        <div class=""><strong>Duration: </strong> <span> {{ item.duration }}</span></div>
                    </div>
                    <div class="col-12">
                        <div class=""><strong>Date: </strong> {{ $filters.date(item.created_at, 1) }}</div>
                    </div>
                    <div class="col-12">
                        <hr class="my-1">
                        <div class=""><strong>Instructions </strong></div>
                        <div v-html="preText(item.instructions)"></div>
                    </div>
                </div>
            </div>
            <slot name="actions"></slot>
        </div>
    </div>
    <b-modal hide-footer v-model="showEvidence" centered
        title="Attachment" @hide="showEvidence = false">
        <div class="row g-3">
            <div class="col-6">
                <img class="w-100" :src="absoluteUrl(item.image)" />
            </div>
            <div class="col-6">
                <img class="w-100" :src="absoluteUrl(item.receipt_signature)" />
            </div>
        </div>
    </b-modal>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type: Object,
            required: true
        }
    },
    data(){
        return {
            showEvidence: false
        }
    },
    methods:{
        downloadReceipt(itemId) {
            const payload = {
                url: `${this.absoluteUrl()}/api/download-receipt`,
                data: {  model_id: btoa(itemId), receipt: '4bb74e97_1e2f_4f3c_bd05_11d3744fb0db' }
            }
            this.$store.dispatch("changeLoaderValue", true)
            this.serverReceipt(payload)
                .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.status != 200){
                    this.alertError('Could not generate receipt');
                }
            })
        }, 
    }
}
</script>

<style>

</style>